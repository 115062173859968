<template>
  <v-container>
    <Loader v-if="loading" />
    <v-alert v-else-if="data.status === 0" border="left" colored-border color="warning" elevation="10">
      <v-icon x-large color="warning">mdi-alert-circle-outline</v-icon>
      Votre paiement n'a pas pu être effectué.
    </v-alert>
    <v-alert v-else-if="data.status === 1" border="left" colored-border color="success" elevation="10">
      <v-icon x-large color="success">mdi-account-check</v-icon>
      Votre paiement a été accepté.
    </v-alert>
  </v-container>
</template>

<script>
import Loader from "@/components/Admin/Loader";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      userId: "",
      loading: true,
      data: {},
    };
  },
  methods: {},
  async mounted() {
    try {
      this.loading = true;

      const { data } = await this.$services.organization.getPaymentConfirmation(
        this.$route.params.id
      );

      console.dir(data);

      this.data = data;
    } catch (e) {
      this.$helpers.snackbar.handleError(e);
    } finally {
      this.loading = false;
    }
  },
};
</script>